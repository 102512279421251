import axios from 'axios';
import {
  API_ENDPOINTS,
  AppContainer,
  AuthPaneContainer,
  Button,
  COOKIE_TYPES,
  customToast,
  FormInput,
  getLocalUrl,
  getThemeFromUrl,
  LangContext,
  LargeThemeLogo,
  setCookie,
  Themes,
  Title,
  Variants,
  route,
  Spinner,
  StringHelpers,
  isCookieSet,
} from 'carrier-fe';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface Response {
  data: {
    token: {
      token: string;
    };
  };
  message: string;
}

function Login() {
  const [data, setData] = useState({
    username: '',
    password: '',
  });
  const [errors, setErrors] = useState<{
    username: string[];
    password: string[];
  } | null>(null);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  const { getLangPage } = useContext(LangContext);
  const [lang, setLang] = useState<any>(null);

  useEffect(() => {
    if (lang === null) {
      load();
    }

    if (isCookieSet(COOKIE_TYPES.TOKEN)) {
      navigate('/dashboard');
    }
  }, []);

  useEffect(() => {
    if (submitting) {
      setErrors(null);
      submit();
    }
  }, [submitting]);

  const load = async () => {
    getLangPage('dashboard/login')
      .then((res: any) => {
        if (typeof res === "undefined" || res === null) {
          setLang([]);
        } else {
          setLang(res);

          document.title = res?.page.title ?? 'Login';
        }
      })
      .catch((err: any) => console.error(err));
  };

  const submit = () => {
    axios
      .post<Response>(route(API_ENDPOINTS.AUTH.LOGIN), data)
      .then((res: any) => {
        const token = res.data.data.token.token;

        if (token) {
          setCookie(COOKIE_TYPES.TOKEN, token);
        }

        customToast({
          title: res.data.message,
          variant: Variants.Success,
        });

        navigate('/dashboard');
      })
      .catch((error: any) => {
        if (!!error && !!error.response && !!error.response.data) {
          if (!!error.response.data.message) {

            customToast({
              title: error.response.data.message,
              variant: Variants.Danger,
            });
          }

          if (!!error.response.data.data && !!error.response.data.data.errors) {
            setErrors(error.response.data.data.errors);
          }
        } else {
          console.log(error);
          customToast({
            title: 'An error occurred. Please refresh the page to try again.',
            variant: Variants.Danger,
          });
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleFieldUpdate = (field: string, value: any) => {
    let cloned: any = structuredClone(data);

    cloned[field] = value;

    setData(cloned);
  };

  const renderButtonTitle = () => {
    if (submitting) {
      return lang?.page.submit_button.submitting || 'Logging in...';
    }
    return lang?.page.submit_button.default || 'Login';
  };

  // TODO - Create a branded loading spinner
  if (lang === null) {
    return (
      <div className="d-flex align-items-center vh-100 justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <AppContainer>
      <AuthPaneContainer fillerImage={getLocalUrl() + '/auth_cover.png'}>
        <LargeThemeLogo theme={getThemeFromUrl()} />
        <Title
          title={StringHelpers.title(lang?.page.title || 'Login')}
          className="mb-3 text-center w-100"
        />
        <FormInput
          type="username"
          label={StringHelpers.title(lang?.fields.username || 'Username')}
          name="username"
          value={data.username}
          onChange={(value) => handleFieldUpdate('username', value)}
          errorMessages={!!errors && !!errors.username ? errors.username : null}
          disabled={submitting}
        />
        <FormInput
          type="password"
          label={StringHelpers.title(lang?.fields.password || 'Password')}
          name="password"
          value={data.password}
          onChange={(value) => handleFieldUpdate('password', value)}
          errorMessages={
            !!errors && !!errors.password ? errors.password : null
          }
          disabled={submitting}
        />
        <div
          className="d-flex justify-content-end align-items-center mb-5"
          style={{ marginTop: '-1rem' }}
        >
          <Link
            className="text-decoration-none"
            to="/forgot-password"
          >
            {lang?.page.links.forgot_password || 'Forgot password'}
          </Link>
        </div>
        <Button
          label={renderButtonTitle()}
          onClick={() => setSubmitting(true)}
          disabled={submitting}
          variant={Variants.Info}
          className="w-100 justify-content-center mb-3 login-button"
        />
        {getThemeFromUrl() === Themes.Viessmann && (
          <Button
            label={
              lang?.page.viessmann.submit_button.default ||
              'Login with Viessmann account'
            }
            onClick={() => navigate('/viessmann/login')}
            disabled={submitting}
            variant={Variants.Warning}
            className="w-100 justify-content-center mb-3 login-button"
          />
        )}
        <div className="w-full d-flex justify-content-center align-items-center gap-2">
          <Link
            className="text-decoration-none"
            to="/register"
          >
            {lang?.page.links.register ||
              'No account? Register here'}
          </Link>
        </div>
      </AuthPaneContainer>
    </AppContainer>
  );
}

export default Login;
